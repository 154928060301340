import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const User = () => import('@/views/user/listuser')
const TambahUser = () => import('@/views/user/tambahuser')
const EditUser = () => import('@/views/user/edituser')

const Pohon = () => import('@/views/pohon/listpohon')
const TambahPohon = () => import('@/views/pohon/tambahpohon')
const EditPohon = () => import('@/views/pohon/editpohon')

const Taman = () => import('@/views/taman/listtaman')
const TambahTaman = () => import('@/views/taman/tambahtaman')
const EditTaman = () => import('@/views/taman/edittaman')

const Pengajuan = () => import('@/views/pengajuan/listpengajuan')
const TambahPengajuan = () => import('@/views/pengajuan/tambahpengajuan')
const EditPengajuan = () => import('@/views/pengajuan/editpengajuan')

const Penebangan = () => import('@/views/penebangan/listpenebangan')
const TambahPenebangan = () => import('@/views/penebangan/tambahpenebangan')
const EditPenebangan = () => import('@/views/penebangan/editpenebangan')

const Informasi = () => import('@/views/informasi/listinformasi')
const TambahInformasi = () => import('@/views/informasi/tambahinformasi')
const EditInformasi = () => import('@/views/informasi/editinformasi')

const Artikel = () => import('@/views/artikel/listartikel')
const TambahArtikel = () => import('@/views/artikel/tambahartikel')
const EditArtikel = () => import('@/views/artikel/editartikel')

const Foto = () => import('@/views/foto/listfoto')
const TambahFoto = () => import('@/views/foto/tambahfoto')
const EditFoto = () => import('@/views/foto/editfoto')

const Profilku = () => import('@/views/profilku/profilku')
const GantiPassword = () => import('@/views/ubahpassword/ubahpassword')

const LaporanPohon = () => import('@/views/laporan/laporanpohon')
const LaporanTaman = () => import('@/views/laporan/laporantaman')

const Pos = () => import('@/views/Pos')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)
let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'pos',
          name: 'Pos',
          component: Pos,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'Profilku',
          name: 'profilku',
          component: Profilku,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'gantipassword',
          name: 'GantiPassword',
          component: GantiPassword,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'laporanpohon',
          name: 'LaporanPohon',
          component: LaporanPohon,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'laporantaman',
          name: 'LaporanTaman',
          component: LaporanTaman,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'pohon',
          redirect: '/pohon/list',
          name: 'Pohon',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Pohon',
              component: Pohon,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Pohon',
              component: TambahPohon,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id',
              name: 'Edit Pohon',
              component: EditPohon,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'taman',
          redirect: '/taman/list',
          name: 'Taman',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Taman',
              component: Taman,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Taman',
              component: TambahTaman,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id',
              name: 'Edit Taman',
              component: EditTaman,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'pengajuan',
          redirect: '/pengajuan/list',
          name: 'Pengajuan',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Pengajuan',
              component: Pengajuan,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Pengajuan',
              component: TambahPengajuan,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_pengajuan_penebangan',
              name: 'Edit Pengajuan',
              component: EditPengajuan,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        
        {
          path: 'penebangan',
          redirect: '/penebangan/list',
          name: 'Penebangan',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Penebangan',
              component: Penebangan,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Penebangan',
              component: TambahPenebangan,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_penebangan',
              name: 'Edit Penebangan',
              component: EditPenebangan,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        
        {
          path: 'informasi',
          redirect: '/informasi/list',
          name: 'Informasi',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Informasi',
              component: Informasi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Informasi',
              component: TambahInformasi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_informasi_pohon',
              name: 'Edit Informasi',
              component: EditInformasi,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },

        {
          path: 'artikel',
          redirect: '/artikel/list',
          name: 'Artikel',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Artikel',
              component: Artikel,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Artikel',
              component: TambahArtikel,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_berita',
              name: 'Edit Artikel',
              component: EditArtikel,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },

        {
          path: 'foto',
          redirect: '/foto/list',
          name: 'Foto',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Foto',
              component: Foto,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Foto',
              component: TambahFoto,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_gallery',
              name: 'Edit Foto',
              component: EditFoto,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        
        {
          path: 'user',
          redirect: '/user/list',
          name: 'User',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List User',
              component: User,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add User',
              component: TambahUser,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_user',
              name: 'Edit User',
              component: EditUser,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        guest: true
      }
    },
    // {
    //   path: '/pages',
    //   redirect: '/pages/404',
    //   name: 'Pages',
    //   component: {
    //     render(c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Page404',
    //       component: Page404
    //     },
    //     {
    //       path: '500',
    //       name: 'Page500',
    //       component: Page500
    //     },
    //   ]
    // }
  ]
}

// Meta Handling


// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') === '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       let user = JSON.parse(localStorage.getItem('user'))
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       if (to.matched.some(record => record.meta.is_admin)) {
//         next({ path: '/dashboard' })
//       } else {
//         next()
//       }
//     }
//   } else if (to.matched.some(record => record.meta.guest)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       next({ path: '/dashboard' })

//     }
//   } else {
//     next()
//   }
// })

const redirectToLogin = route => {
  const LOGIN = 'Login';
  if (route.name != LOGIN) {
    return { name: LOGIN, replace: true, query: { redirectFrom: route.fullPath } };
  }
};

const redirectToHome = route => {
  const DEFAULT = 'Dashboard';
  return { name: DEFAULT, replace: true };
};

const DEFAULT_TITLE = 'SIPPohon';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == "") {
  //     router.push({ path: '/login' });
  //   } else {
  //     let user = JSON.parse(localStorage.getItem('user'))
  //     if (user == null) {
  //       if (user.level == 1) {
  //         router.push({ path: '/dashoard' });
  //       } else {
  //         // next({ path: '/dashboard' })
  //         router.push({ path: '/dashoard' });
  //       }
  //     } else {
  //       router.push({ path: '/login' });
  //     }
  //   }
  // } else if (to.matched.some(record => record.meta.guest)) {
  //   if (localStorage.getItem('jwt') == null) {
  //     router.push({ path: '/login' });
  //   } else {
  //     next({ path: '/dashboard' })
  //   }
  // } else {
  //   router.push({ path: '/login' });
  // }

  const userIsAuthenticated = localStorage.getItem('jwt');
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  // if (localStorage.getItem('jwt') == null) {
  //   router.push({ path: '/login' });
  // } else {
  //   next({ path: '/dashboard' })
  // }
  // if (!userIsAuthenticated && to.fullPath === '/') {
  //   alert('ERROR1');
  //   return redirectToLogin(to);
  // }
  // alert(to.path);
  if (to.path == '/register') {
    next();
  }
  else if (to.path !== '/login') {
    if (requiresAuth) {
      if (!userIsAuthenticated) {
        alert('Maaf! Anda harus login terlebih dahulu');
        router.push({ path: '/login' });
      }
      else {
        // alert('ERROR1');
        next();
      }
    }
    else {
      // alert('ERROR3');
      next({ path: '/login' })
    }


    return true;
  }
  else {
    next()
  }
});

export default router;

